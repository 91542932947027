import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";

import LayoutComponent from "./components/Layout";

const Login = lazy(() => import("./pages/Login"));

const Home = lazy(() => import("./pages/Home"));
const NotFound = lazy(() => import("./pages/NotFound"));
const RegisteredUsers = lazy(() => import("./pages/RegisteredUsers"));
const Start = lazy(() => import("./pages/Start"));
const AddEvent = lazy(() => import("./pages/AddEvent"));

// const HomePage = lazy(() => import("./pages/HomePage"));

const getElement = (isLoggedIn: boolean) => {
  if (isLoggedIn) {
    return <Navigate to="/start/" />;
  }

  // localStorage.clear();
  return <Navigate to="/login" />;
};

const getElementsForAdmin = (isLoggedIn: boolean) => {
  if (isLoggedIn) {
    return <LayoutComponent />;
  }

  // localStorage.clear();
  return <Navigate to="/login" />;
};

const routes = (isLoggedIn: boolean) => [
  //without Layout
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: getElement(isLoggedIn)
      },
      {
        path: "login",
        element: !isLoggedIn ? <Login /> : <Navigate to="/start/" />
      },
      {
        path: "start",
        element: !isLoggedIn ? <Login /> : <Start />
      },
      // {
      //   path: "signup",
      //   element: !isLoggedIn ? <SignUp /> : <Navigate to="/start/" />
      // },
      { path: "*", element: <NotFound /> }
    ]
  },
  //with Layout
  {
    path: "home",
    element: getElementsForAdmin(isLoggedIn),

    children: [
      { path: "", element: <Home /> },
      { path: "addEvent", element: <AddEvent /> },
      { path: ":organiserId", element: <Home /> },
      { path: ":organiserId/:eventId", element: <RegisteredUsers /> },
      { path: "*", element: <NotFound /> }
    ]
  }
];

export default routes;
