import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { instance } from "../../api/api";
import { BootstrapApiResponse } from "../../types/commonTypes";
import { retryQueryFunction } from "../../utils/helperFunctions";

const fetchBootstrapApi = () => {
  return instance.get(`/organiser/bootstrap-api`);
};

export const useBootstrap = ({ onError }: { onError: (error: AxiosError) => void }) => {
  return useQuery(["fetch-bootstrap"], () => fetchBootstrapApi(), {
    retry: (failureCount, error) => retryQueryFunction(failureCount, error),
    onError,
    refetchOnWindowFocus: false,
    // cacheTime: 1000 * 60 * 30, //30min cache time
    // staleTime: 1000 * 60 * 30, //30min stale time
    select: (data: { data: BootstrapApiResponse }) => {
      return data?.data;
    }
  });
};
