import { Suspense, useState, useEffect } from "react";
import { Spin } from "antd";
import { useRoutes } from "react-router-dom";
import routes from "./routing";
import useAuthStore from "./zustand/authStore";
import { getUserToken, hasUserDetails } from "./utils/helperFunctions";
import { instance } from "./api/api";
import { LOCAL_STORAGE_CONTANTS } from "./utils/constants";

import { Provider, ErrorBoundary } from "@rollbar/react";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV
};

function App() {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [loading, setLoading] = useState(true);
  const loginSuccess = useAuthStore((state) => state.loginSuccess);

  // initial Login Check
  useEffect(() => {
    const checkIsLoggenIn = async () => {
      if (hasUserDetails()) {
        const JWT_TOKEN = getUserToken();

        if (JWT_TOKEN) {
          instance.defaults.headers.common["Authorization"] = `Token ${JWT_TOKEN}`;
          if (!isLoggedIn) {
            const data = localStorage.getItem(LOCAL_STORAGE_CONTANTS.USER_DETAILS);
            if (data) {
              const formattedData = JSON.parse(data);
              loginSuccess({ ...formattedData });
            }
          }
        }
      }
      setLoading(false);
    };
    checkIsLoggenIn();
  }, []);

  const content = useRoutes(routes(isLoggedIn));

  if (loading) {
    return <FallbackUI />;
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <div className="App">
          <Suspense fallback={<FallbackUI />}>{content}</Suspense>
        </div>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;

const FallbackUI = () => {
  return (
    <div className="fallback-ui">
      <Spin tip="Loading..." />
    </div>
  );
};
