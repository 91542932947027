export const timeToUndoDeleteRowForAddTask = 10;

export enum FilterTypes {
  input = "input",
  date = "date",
  select = "select",
  inputNumber = "inputNumber"
}

//Tags Colors
export const tagsColor = {
  activeTagColor: "#00C6AE",
  inactiveTagColor: "#D3D3D3",
  disabledTagColor: "#D3D3D3",
  dangerTagColor: "#FF4D4F",
  warningTagColor: "#FAAD14",
  successTagColor: "#52C41A",
  primaryTagColor: "#2db7f5"
};

export const NUMBER_CONTANTS = {
  maxSizeForUpload: 2 * 1024 * 1024,
  twoMb: 2 * 1024 * 1024,
  tenMb: 10 * 1024 * 1024,
  timeForToastSuccess: 5,
  timeForToastError: 7,
  CSV_DOWNLOAD_LIMIT: 1000,
  MOBILE_VIEW_THRESHOLD: 767
};

// Local Storage Constants
export const LOCAL_STORAGE_CONTANTS = {
  USER_DETAILS: "USER_DETAILS"
};

export const TEXT_CONSTANTS = {
  ruppee_symbol: "₹",
  emailText: "We will send payment and event relate details to you",
  whatsappText: "We will send events related details to you",
  nriText: "If you are NRI, contact Bhaago India before booking.",
  helpText: {
    initial: "For any help, contact us at",
    email: "hello@bhaagoindia.com"
  },
  reAssignText:
    "Are you sure you want to reassign? Please ensure that all the items are handed over to the new assignee.",
  ERROR_FALLBACK_TEXT: "Something went wrong, Please try again later!",
  DASHBOARD_PORTAL_NAME: "BHAAGO INDIA ORGANISERS DASHBOARD",
  USER_DETAILS: "USER_DETAILS",
  LOGIN_STATUS_FALSE: "Login Status is false, contact Bhaago India"
};

export const patternRegex = {
  gstin: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  aadhar: /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
  pan: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  wholePositive: /^(0|[1-9]\d*)$/,
  onlyPositive: /^([1-9]\d*)$/,
  postiveNumber: /^[+]?([0-9]+(?:[\\.][0-9]*)?|\.[0-9]+)$/,
  postiveNumberAboveZero: /^[+]?([1-9]+(?:[\\.][1-9]*)?|\.[1-9]+)$/,
  // mobile: /^[0-9]{10}$/,
  mobile: /^[6-9]\d{9}$/, //10 digit validation and number doesn't start with digits 0-5
  contact: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  email:
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
};
