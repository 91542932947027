import { message, notification } from "antd";
import { TEXT_CONSTANTS, LOCAL_STORAGE_CONTANTS, NUMBER_CONTANTS } from "./constants";
import type { RangePickerProps } from "antd/es/date-picker";
import { instance } from "../api/api";
import { FilterValue } from "antd/lib/table/interface";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { AxiosError } from "axios";
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export const onError = (error: any) => {
  const msg = error?.response?.data?.message || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
  // message.error(msg, NUMBER_CONTANTS.timeForToastError);
  showErrorNotification({ desc: msg });
};

export const onErrorNotification = (error: any) => {
  const desc = error?.response?.data?.message
    ? error?.response?.data?.message
    : error?.response?.data?.error
    ? error?.response?.data?.error
    : TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
  notification["error"]({
    message: "Error",
    description: desc,
    duration: NUMBER_CONTANTS.timeForToastError,
    placement: "topRight"
  });
};

export const showErrorToast = (msg: string) => {
  message.error(msg || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT, NUMBER_CONTANTS.timeForToastError);
};

export const showSuccessToast = (msg: string) => {
  message.success(msg || "Successfully done!", NUMBER_CONTANTS.timeForToastSuccess);
};

export const showErrorNotification = ({
  msg,
  desc,
  duration
}: {
  msg?: string;
  desc: string;
  duration?: number;
}) => {
  notification["error"]({
    message: msg || "Error",
    description: desc || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT,
    duration: duration ?? NUMBER_CONTANTS.timeForToastError,
    placement: "topRight"
  });
};

interface DownloadCSV {
  uri: string;
  fileName: string;
}

export const downloadCSVWithInstance = async ({ uri, fileName }: DownloadCSV) => {
  try {
    const response = await instance({
      url: uri,
      method: "GET",
      responseType: "blob" // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error: any) {
    const msg = error?.response?.data?.message || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
    showErrorToast(msg);
  }
};

export const formatArray = (array: []) => {
  const newArray: Record<string, unknown>[] = [];
  if (array && array.length) {
    array.forEach((obj: { id: string; name?: string; title?: string }) => {
      newArray.push({
        key: obj.id || obj,
        label: obj.name || obj.title || obj
      });
    });
  }
  return newArray;
};

export const sampleDownload = () => {
  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(new Blob(["Test,Text"], { type: "text/csv" }));
  a.download = "test.csv";

  // Append anchor to body.
  document.body.appendChild(a);
  a.click();

  // Remove anchor from body
  document.body.removeChild(a);
};

export const getType = (obj: unknown) => {
  const lowerCaseTheFirstLetter = (str: string | any[]) => str[0].toLowerCase() + str.slice(1);
  const type = typeof obj;
  if (type !== "object") {
    return type;
  }

  return lowerCaseTheFirstLetter(
    Object.prototype.toString.call(obj).replace(/^\[object (\S+)\]$/, "$1")
  );
};
// getType([]); // "array"
// getType("123"); // "string"
// getType(null); // "null"
// getType(undefined); // "undefined"
// getType(); // "undefined"
// getType(function () {
//   console.log("Hello World");
// }); // "function"
// getType(/123/g); // "regExp"
// getType(new Date()); // "date"
// getType(new Map()); // "map"
// getType(new Set()); // "set"
const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// Used for disabling date in the antd date picker
export const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current > dayjs().subtract(5, "year");
};

// Used for disabling time in the antd date picker
export const disabledDateTime = (current: unknown) => {
  return {
    disabledHours: () => {
      if (current) {
        return [];
      }
      const startHour = new Date().getHours() + 1;
      return range(0, startHour);
    },
    disabledMinutes: () => {
      if (current) {
        return [];
      }
      return range(0, 60);
    },
    disabledSeconds: () => []
  };
};

export const getFileSize = (size: number) => {
  if (size === 0) return "0 Bytes";

  const k = 1024;

  const sizes = ["Bytes", "KB", "MB", "BG", "TB"];

  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const checkNoDataInAObject = (obj: Record<string, any> | undefined) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

export const createQueryParamFromFilterValue = ({
  filtersValue,
  substitueKeys
}: {
  filtersValue: Record<string, FilterValue | null>;
  substitueKeys:
    | {
        actualKey: string;
        changeToKey: string;
      }[]
    | [];
}) => {
  try {
    if (!filtersValue || Object?.keys(filtersValue)?.length === 0) return "";

    const keys = Object.keys(filtersValue);
    let output = "";

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = filtersValue[key] && filtersValue[key]?.[0]?.toString();
      if (value) {
        // checking if key need to be changed with substitue key
        const keyTOBeUsed = keyPresentInArrayOfObjects(substitueKeys, key);

        output += `&${keyTOBeUsed}=${value}`;
      }
    }

    return output;
  } catch (error) {
    console.log("createFiltersFromFilterValue error: ", error);
  }
  return "";
};

export const keyPresentInArrayOfObjects = (
  array:
    | []
    | {
        actualKey: string;
        changeToKey: string;
      }[],
  key: string
) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].actualKey === key) {
      return array[i].changeToKey;
    }
  }

  return key;
};

export const createFiltersFromFilterValue = ({
  filtersValue,
  equalKeys,
  substitueKeys
}: {
  filtersValue: Record<string, FilterValue | null>;
  equalKeys: string[];
  substitueKeys:
    | {
        actualKey: string;
        changeToKey: string;
      }[]
    | [];
}) => {
  try {
    if (Object.keys(filtersValue)?.length === 0) return "";

    const keys = Object.keys(filtersValue);
    let output = "";

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = filtersValue[key] && filtersValue[key]?.[0]?.toString();
      if (value) {
        // checking if key need to be changed with substitue key
        const keyTOBeUsed = keyPresentInArrayOfObjects(substitueKeys, key);
        if (equalKeys.includes(key)) {
          output += `${keyTOBeUsed}:equal:${value},`;
        } else {
          output += `${keyTOBeUsed}:like:${value},`;
        }
      }
    }

    // removing end coma
    if (output?.length > 0) {
      output = output.slice(0, -1);
    }

    return output;
  } catch (error) {
    console.log("createFiltersFromFilterValue error: ", error);
  }
  return "";
};

export const clearCookiesAndLocalStorage = () => {
  const cookieArray = document.cookie.split("; ");
  cookieArray.forEach((cookie) => {
    const key = cookie.split("=")[0];
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });

  localStorage.clear();
  return true;
};

// GET PERMISSIONS DATA
export const getPermissionsData = () => {
  try {
    const data = localStorage.getItem("PERMISSIONS_DATA");
    if (data) {
      return JSON.parse(data);
    }
    return {};
  } catch (error) {
    console.log({ error });
  }
};

export const getUniqueKey = () => {
  return Math.random().toString(16).slice(2);
};

export const trimNumbersToAFixedFigit = (value: number | null, fixedFigit = 2) => {
  if (!value) return "";
  return +parseFloat(value.toString()).toFixed(fixedFigit);
};

export const formatDate = (date: string) => {
  if (!date) return "";
  try {
    const formatDate = dayjs(date).format("llll");
    return formatDate;
  } catch (error) {
    console.log(error);
  }
  return "--";
};

export const formatOnlyDate = (date: string) => {
  try {
    const formatDate = dayjs(date).format("ll");
    return formatDate;
  } catch (error) {
    console.log(error);
  }
  return "--";
};

export const secondsToHms = (d: number) => {
  try {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    const formattedTime = (hDisplay + mDisplay + sDisplay).trim();

    if (formattedTime[formattedTime.length - 1] === ",") {
      return formattedTime.slice(0, formattedTime.length - 1);
    }
    return formattedTime;
  } catch (error) {
    console.log(error);
  }

  return "invalid Time";
};

export const hasUserDetails = () => {
  const data = localStorage.getItem(LOCAL_STORAGE_CONTANTS.USER_DETAILS);
  // const cookie = document.cookie;
  if (data) return true;
  return false;
};

export const getUserToken = () => {
  const data = localStorage.getItem(LOCAL_STORAGE_CONTANTS.USER_DETAILS);
  if (data) {
    const formattedData = JSON.parse(data);
    return formattedData.token;
  }
  return null;
};

export const getFormattedTodayDate = () => {
  try {
    const today = new Date().toLocaleDateString("en-GB");
    const split = today.split("/");
    return split[0] + "-" + split[1] + "-" + split[2];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

export const getLastWeekFormattedDate = () => {
  try {
    const today = new Date();
    const prevWeek = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);

    const temp = new Date(prevWeek).toLocaleDateString("en-GB");
    const split = temp.split("/");
    return split[0] + "-" + split[1] + "-" + split[2];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

//GETTING 17-03-2022 AND SENDS 22-03-17
export const changeDDMMYYYYToYYMMDD = (date: string) => {
  try {
    const split = date.split("-");
    return split[2].slice(2) + "-" + split[1] + "-" + split[0];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

//GETTING 2022-03-20 AND SENDS 20-03-2022
export const changeYYYYMMDDToDDMMYYYY = (date: string) => {
  try {
    const split = date.split("-");
    return split[2] + "-" + split[1] + "-" + split[0];
  } catch (error) {
    console.log("Error: ", error);
  }
  return "";
};

export const generateFilterString = (searchHearders: any) => {
  let searchString = "";
  for (const [key, value] of Object.entries(searchHearders)) {
    if (searchString && value) {
      searchString += ",";
    }
    if (value) searchString += value && `${key}:${value}`;
  }
  return searchString;
};

export const setLocalStorage = (name: string, data: any) => {
  const response = JSON.stringify(data);
  localStorage.setItem(name, response);
};

export const getlocalStorage = (name: string) => {
  const response = localStorage.getItem(name);
  return response ? JSON.parse(response) : null;
};

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

export const removelocalStorage = (name: string) => localStorage.removeItem(name);

export const convertToIdNameFromObjects = ({
  idKey,
  nameKey,
  array
}: {
  idKey: string;
  nameKey: string;
  array: Record<string, number | string>[];
}) => {
  if (!array) return [];
  const newArray = [];

  for (let i = 0; i < array.length; i++) {
    const id = array[i][idKey];
    const name = array[i][nameKey];
    newArray.push({ id, name });
  }

  return newArray;
};

export const checkFilterPresentOrNot = ({
  main,
  value
}: {
  main: unknown;
  value: string | number | boolean;
}) => {
  const output = main?.toString()?.toLowerCase()?.includes(value?.toString()?.toLowerCase());
  return output;
};

export const retryQueryFunction = (failureCount: number, error: AxiosError<unknown, any>) => {
  if (error?.request?.status >= 500 && error?.request?.status <= 599) {
    if (failureCount < 2) return true; // will run 3 times, 0, 1, 2
    return false;
  }
  return false;
};
