// import { SizeType } from "antd/es/config-provider/SizeContext";
import Form from "antd/lib/form";
// import { AxiosError } from "axios";

export enum FileComingFrom {
  FRONTEND = "FRONTEND",
  BACKEND = "BACKEND"
}

export interface TempType {
  id: number;
  name: string;
}

export interface NonEditableRowFormProps {
  // label: string;
  value: string | number | boolean | TempType[] | undefined | null;
  type: RowFormInputTypes;
}

export interface EditableRowFormProps {
  label: string;
  value?:
    | string
    | number
    | boolean
    | TempType[]
    | undefined
    | null
    | number[]
    | Record<string, unknown>;
  type: RowFormInputTypes;
  id: string;
  arrayData?: {
    id: number | string;
    name: string;
  }[];
  required?: boolean;
  disabled?: boolean;
  pattern?: RegExp;
  fetchApi?: any;
  labelToShow?: boolean;
  marginBottom?: boolean;
  mode?: "multiple";
  debounceId?: string;
  debounceName?: string;
  debounceName2?: string;
  placeholder?: string;
  // size?: "default" | "small" | "large";
  size?: Parameters<typeof Form>[0]["size"];
}

export interface RowFormProps {
  label: string;
  value: string | number | boolean | string[] | undefined;
  type: RowFormInputTypes;
}

export enum RowFormInputTypes {
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  NUMBER = "NUMBER",
  MULTI_SELECT = "MULTI_SELECT",
  TAG = "TAG",
  SINGLE_SELECT = "SINGLE_SELECT",
  DATE = "DATE",
  DEBOUNCE_SELECT = "DEBOUNCE_SELECT",
  PINCODE = "PINCODE",
  NUMBER_WITH_CUSTOM_RULE = "NUMBER_WITH_CUSTOM_RULE",
  STRING_WITH_CUSTOM_RULE = "STRING_WITH_CUSTOM_RULE",
  PASSWORD = "PASSWORD",
  EMAIL = "EMAIL",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  RADIO_BUTTON = "RADIO_BUTTON"
}

export enum FileUploadButtonType {
  NEW = "NEW",
  UPDATE = "UPDATE"
}

export enum AllowedFileTypes {
  IMAGE = "IMAGE",
  PDF = "PDF"
}

export interface FileUploadProps {
  // index: number;
  buttonType: FileUploadButtonType;
  // fileParams: DocFileType;
  id: string;
  handleFileUpload: ({
    // index,
    id,
    fileParams,
    file
  }: {
    id: string;
    fileParams: DocFileType;
    file: File;
  }) => void;
  maxFileSizeInBytesAllowed?: number;
  accept: string;
}

export interface DocFileType {
  url: string | null;
  fileType: AllowedFileTypes | null;
  fileName?: string | null;
  fileComingFrom: FileComingFrom | null;
}
