import { useEffect, useState, useRef } from "react";
import {
  // DesktopOutlined,
  // FileOutlined,
  PieChartOutlined,
  CodeSandboxOutlined
  // TeamOutlined,
  // UserOutlined
} from "@ant-design/icons";
import { Button, Form, MenuProps } from "antd";
import { Layout, Menu, theme, Spin } from "antd";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { IMAGE_LINKS } from "../../assets/imageLinks";
import styles from "./layout.module.css";
import useAuthStore from "../../zustand/authStore";
import EditableForms from "../Forms/EditableForms";
import { RowFormInputTypes } from "../../types/formTypes";
import { useBootstrap } from "../../hooks/QueryHooks/useBootstrap";
import { convertToIdNameFromObjects, onError } from "../../utils/helperFunctions";
import useWindowSize from "../../hooks/useWindowSize";
import { NUMBER_CONTANTS } from "../../utils/constants";

const { Header, Footer, Sider } = Layout;

// type MenuItem = Required<MenuProps>["items"][number];

// function getItem(
//   label: React.ReactNode,
//   key: React.Key,
//   icon?: React.ReactNode,
//   children?: MenuItem[]
// ): MenuItem {
//   return {
//     key,
//     icon,
//     children,
//     label
//   } as MenuItem;
// }

// const items: MenuItem[] = [
//   getItem("Events Data", "1", <PieChartOutlined />)
//   // getItem("Option 2", "2", <DesktopOutlined />),
//   // getItem("User", "sub1", <UserOutlined />, [
//   //   getItem("Tom", "3"),
//   //   getItem("Bill", "4"),
//   //   getItem("Alex", "5")
//   // ]),
//   // getItem("Team", "sub2", <TeamOutlined />, [getItem("Team 1", "6"), getItem("Team 2", "8")]),
//   // getItem("Files", "9", <FileOutlined />)
// ];

const LayoutComponent: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const [form] = Form.useForm();

  const params = useParams();
  const organiserId = params.organiserId || form.getFieldValue("organiser_selected");
  const navigate = useNavigate();

  const { isLoading, data: bootstrapData } = useBootstrap({ onError });
  const initialOrganiserSelectedRef = useRef(false);
  const logout = useAuthStore((state) => state.logout);

  const organiserSelectedValue = Form.useWatch("organiser_selected", form);

  const size = useWindowSize();

  // changing the url if organiser selection is changed and initially setting the selected organised based on url
  useEffect(() => {
    // setting the data initially once based on url
    if (organiserId && !initialOrganiserSelectedRef.current && !organiserSelectedValue) {
      form.setFieldValue("organiser_selected", +organiserId);
      initialOrganiserSelectedRef.current = true;
    }

    // when user clicks on browser back
    if (
      initialOrganiserSelectedRef.current &&
      organiserId &&
      organiserSelectedValue &&
      +organiserId !== organiserSelectedValue
    ) {
      form.setFieldValue("organiser_selected", +organiserId);
    }
  }, [organiserId]);

  useEffect(() => {
    if (
      initialOrganiserSelectedRef.current &&
      organiserId &&
      organiserSelectedValue &&
      +organiserId !== organiserSelectedValue
    ) {
      // naviagate to other value
      navigate(`/home/${organiserSelectedValue}`);
    }
  }, [organiserSelectedValue]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible={
          size.width && size.width > NUMBER_CONTANTS.MOBILE_VIEW_THRESHOLD ? true : false
        }
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className={styles.sidebar}
        breakpoint="lg"
        // collapsedWidth="0"
      >
        <img
          src={IMAGE_LINKS.LOGO_URL}
          alt="bhaago india logo"
          // className={styles.logo}
          width="72"
          height="72"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />
        {/* <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          className={styles.menu}
        /> */}
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" className={styles.menu}>
          <Menu.Item key="1">
            <PieChartOutlined />
            <span>Events Data</span>
            <Link to={`/home/${organiserId}`} />
          </Menu.Item>
          {/* <Menu.Item key="2">
            <CodeSandboxOutlined />
            <span>Add Event</span>
            <Link to={`/home/addEvent`} />
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 200
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer
          }}
        >
          <div className={styles.headerContainer}>
            <Spin spinning={isLoading}>
              <Form
                form={form}
                name="select_organiser"
                // onFinish={onFinish}
                layout="horizontal"
                initialValues={{ auto_settle: true }}
              >
                <EditableForms
                  label={
                    size.width && size.width > NUMBER_CONTANTS.MOBILE_VIEW_THRESHOLD
                      ? "Organiser Selected"
                      : ""
                  }
                  id="organiser_selected"
                  type={RowFormInputTypes.SINGLE_SELECT}
                  labelToShow={true}
                  marginBottom={false}
                  arrayData={convertToIdNameFromObjects({
                    idKey: "organiser__id",
                    nameKey: "organiser__name",
                    array: bootstrapData?.organiser as unknown as Record<string, string | number>[]
                  })}
                />
              </Form>
            </Spin>
            <Button onClick={() => logout()}>Logout</Button>
          </div>
        </Header>
        <Outlet />
        {/* <Footer style={{ textAlign: "center" }}>
          © {new Date().getFullYear()} BhaagoIndia. All Rights Reserved.
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
