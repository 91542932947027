import { LoginSuccessResponse } from "./../types/authTypes";
import { create } from "zustand";

interface AuthState {
  isLoggedIn: boolean;
  authData: LoginSuccessResponse | null;
  loginSuccess: (data: LoginSuccessResponse) => void;
  logout: () => void;
}

const useAuthStore = create<AuthState>((set) => ({
  isLoggedIn: false,
  authData: null,
  loginSuccess: (data) => set(() => ({ isLoggedIn: true, authData: data })),
  logout: () => {
    localStorage.clear();
    set({ isLoggedIn: false, authData: null });
  }
}));

export default useAuthStore;
