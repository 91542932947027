import axios from "axios";
// import { getUserToken } from "../utils/helperFunctions";

const backendUrl = process.env.REACT_APP_API_URL;
const testBackendUrl = process.env.REACT_APP_API_TEST_URL;
const appVersion = process.env.REACT_APP_VERSION;

// For common configs
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["mode"] = "no-cors";
// axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Access-Control-Allow-Headers"] =
//   "Origin, X-Requested-With, Content-Type, Accept";

export const instance = axios.create({
  baseURL: backendUrl,
  headers: {
    // Authorization: `Bearer ${getUserToken()}`,
    Version: appVersion
  }
});

// Api response check for Instance
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response) {
      // For unauthorized ie if 401, then redirecting to logout page of Old Admin Panel
      const { status, statusText } = error.response;
      if (status === 401 || statusText === "Unauthorized") {
        localStorage.clear();
        const url = process.env.REACT_APP_URL + "/login";
        window.location.href = url;
      }
    }

    return Promise.reject(error);
  }
);

export const testInstance = axios.create({
  baseURL: testBackendUrl,
  headers: {
    // Authorization: `Bearer ${getUserToken()}`,
    Version: appVersion
  }
});

// Api response check for Instance
testInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response) {
      // For unauthorized ie if 401, then redirecting to logout page of Old Admin Panel
      const { status, statusText } = error.response;
      if (status === 401 || statusText === "Unauthorized") {
        localStorage.clear();
        const url = process.env.REACT_APP_OLD_ADMIN_APP_URL + "/login";
        window.location.href = url;
      }
    }

    return Promise.reject(error);
  }
);
